<template>
  <div class="StudentSurplusLesson">
    <div class="search-item">
        <a-tree-select
            style="width: 250px;margin-right: 10px;"
            v-model="OrgIds"
            :tree-data="treeData"
            tree-checkable
            :maxTagCount="1"
            placeholder="请选择搜索范围"
            @change="onOrgChange"
            :show-checked-strategy="SHOW_PARENT"
          />

        <a-select placeholder="类型" v-model="StudentStatus"  style="width: 150px;margin-right: 10px;">
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="1">沉睡会员</a-select-option>
            <a-select-option value="2">退费意向</a-select-option>
        </a-select>

        <a-select placeholder="合同状态" v-model="ContractStatus"  style="width: 150px;margin-right: 10px;">
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="1">有效期内</a-select-option>
            <a-select-option value="2">有效期外</a-select-option>
        </a-select>

        <a-select placeholder="剩余类型" v-model="SurplusType"  style="width: 150px;margin-right: 10px;">
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="SurplusLessonNum">剩余基本</a-select-option>
            <a-select-option value="SurplusCourseVoucherNum">剩余赠送</a-select-option>
        </a-select>

        <a-input type="number" v-model="SurplusLessonNum" placeholder="剩余课次" style="width: 150px;margin-right: 10px;"/>

        <a-button style="margin-right: 10px;" @click="reset">重 置</a-button>

        <a-button type="primary" @click="_search">搜 索</a-button>

    </div>

	<div class="table-list">
        <a-table :columns="columns" :data-source="dataList" :pagination="false" @change="handleTableChange"></a-table>
        <a-pagination show-size-changer v-model="currPage" v-if="DataCount > 10" style="margin-top: 18px;text-align: right;" @change="_changePage" :total="DataCount"  show-less-items :page-size-options="pageSizeOptions" show-quick-jumper @showSizeChange="onShowSizeChange" />
    </div>


  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import moment from 'moment'
import OnlineClassStudent from 'pages/ReportCenter/OnlineClassStudent'
import OnlineClassNet from 'pages/ReportCenter/OnlineClassNet'
import OnlineClassDevice from 'pages/ReportCenter/OnlineClassDevice'
import OnlineClassStudentList from 'pages/ReportCenter/OnlineClassStudentList'
import * as echarts from 'echarts';
export default {
	name: 'StudentSurplusLesson',
    components: { OnlineClassStudent, OnlineClassNet, OnlineClassDevice, echarts, OnlineClassStudentList },
    data () {
        return {
            userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
            dataList :[],
            RangesConf:{},
            SelectId:'',
            StartDate:undefined,
            EndDate:undefined,
            defaultDate:[moment().week(moment().week()).startOf('week'),moment()],
            currPage:1,
            DataCount:0,
            LabelName:'ClassSummary|Individual|',
            OrgIds:[],
            SelectDataList:[],
            func:'',
            columns:[
                {
                    dataIndex: 'Num',
                    key: 'Num',
                    title: '序号'
                },
                {
                    title: '会员姓名',
                    dataIndex: 'StudentName',
                    key: 'StudentName',
                },
                {
                    title: '电话',
                    dataIndex: 'PhoneNum',
                    key: 'PhoneNum',
                },
                {
                    title: '所属中心',
                    dataIndex: 'SchoolName',
                    key: 'SchoolName',
                },
                {
                    title: '剩余基本课次',
                    dataIndex: 'SurplusLessonNum',
                    key: 'SurplusLessonNum',
                    sorter:true,
                },
                {
                    title: '剩余赠送',
                    dataIndex: 'SurplusCourseVoucherNum',
                    key: 'SurplusCourseVoucherNum',
                    sorter:true,
                },
                {
                    title: '最后上课时间',
                    dataIndex: 'LastClassDate',
                    key: 'LastClassDate',
                    sorter:true,
                },
            ],
            shade:false,
            CurrPlayUrl:'',
            treeData: [],
            SchoolIds:[],
            SHOW_PARENT: 'SHOW_ALL',
            ShowStudentVisible:false,
            queryData:'',
            OnlineClassNetData:'',
            OnlineClassNetVisible:false,
            OnlineClassDeviceData:'',
            OnlineClassDeviceVisible:false,
            CurrShowStr:'',
            NetData:[],
            Num:'',
            Keyword:'',
            summary_data:'',
            OrderField:'',
            Order:'',
            RemarksVisible:false,
            StudentStatus:undefined,
            ContractStatus:undefined,
            SurplusLessonNum:undefined,
            pageSizeOptions:['10', '20', '40', '50','100'],
            Limit:10,
            SurplusType:undefined,
        }
    },
    created () {

        this._treeData();

        this._getData();
    },
    mounted(){
        
    },
    methods: {
        moment,
        onDateChange(v){
            if (v.length > 0) {
                this.StartDate = this.__moment__(v[0]).format('YYYY-MM-DD');
                this.EndDate = this.__moment__(v[1]).format('YYYY-MM-DD');
            }
        },
        handleSelectChange(v){
            this.SelectId = v;
        },
        reset(){
            this.SelectId = '';
            this.OrgIds = [];
            this.StudentStatus = undefined;
            this.ContractStatus = undefined;
            this.SurplusLessonNum = undefined;
            this.SurplusType = undefined;
            this.OrderField = '';
            this.Order = '';
        },
        _search(){
            this.currPage = 1;
            this._getData();
        },
        _getData(){
            this.$message.loading('拉取数据中...')
            this.$axios.post(8000101,{
                    LabelName:'Student|SurplusLesson|index',
                    OrgIds:this.OrgIds,
                    Page:this.currPage,
                    OrderField:this.OrderField,
                    Order:this.Order,
                    StudentStatus:this.StudentStatus,
                    ContractStatus:this.ContractStatus,
                    SurplusLessonNum:this.SurplusLessonNum,
                    Limit:this.Limit,
                    SurplusType:this.SurplusType
                },
                res => {
                    console.log(res)
                    if (res.data.code == 1) {
                        this.dataList = res.data.data.list;
                        this.DataCount = res.data.data.count;
                        this.summary_data = res.data.data.summary_data;
                    } else {
                        this.dataList = [];
                        this.DataCount = 0;
                    }
                    this.$message.destroy()
                }
            )
        },
        _changePage(p){
            this.currPage = p;
            this. _getData();
        },
        _treeData () {
          let self = this
          this.$axios.get(
            1090,
            {
              UserId: self.userinfo.uid
            },
            res => {
              self.treeData = res.data
            }
          )
        },
        handleTableChange(pagination, filters, sorter){

          this.OrderField = sorter.field;

          switch (sorter.order) {
            case 'ascend':
              this.Order = 'asc';
              break;
            case 'descend':
              this.Order = 'desc';
              break;
          }

          this._getData();
        },
        onOrgChange(value, label, text){
            if (text.checked) {
                this.SchoolIds.push(text.triggerValue)
                } else {
                if (this.SchoolIds.length > 0) {
                  for (var i = 0; i < this.SchoolIds.length; i++) {
                    if (text.triggerValue == this.SchoolIds[i]) {
                      this.SchoolIds.splice(i, 1)
                    }
                  }
                }
            }

            this.OrgName = label
            this.OrgIds = value
        },
        onShowSizeChange(current, pageSize){

            this.currPage = current;
            this.Limit = pageSize;

            this. _getData();
        },
    }
}
</script>

<style lang="less" scoped>
.StudentSurplusLesson {
    width: 100%;
    min-height: 100%;
    background-size: 100%;
    position: relative;
    border-radius: 5px;
    background-color: #FFF;
    padding: 15px;
}

.table-list{
    margin-top: 30px;
    margin-left: 20px;
}

.search-item{
    margin-left: 19px;
    margin-top: 33px;
    display: inline-flex;
}

.shade{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .5;
    z-index: 99999;
}
.seevideo{
    position: absolute;
    top: 1%;
    left: 49%;
    transform: translate(-50%, 50%);
    z-index: 999999;
}

.stat-data{
    width: 80%;
    background-color: #eff6ff;
    margin-left: 20px;
    height:150px;
    padding:30px;
    margin-top: 20px;
}

.stat-item{
    width: 22%;
    display: -ms-flexbox;
    height: 90px;
    background-color: #ffffff;
    margin-right: 20px;
    display: inline-block;
}
.stat-text{
    margin: 25px;
}
.stat-num{
    font-weight: 600;
    font-size:18px;
}
.stat-text-title{
    font-weight: 300;
    font-size: 12px;
}
</style>
